import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";

// import { BgImage } from "gbimage-bridge";
// import { BgImgTest } from "../components/tmp/bg-img";
import Quote from "../components/quotes";

/* eslint-disable-next-line */
export interface ONasProps {}

export function ONas(props: ONasProps) {
  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>O nas | Gositlna Spirić</title>
        <link rel="canonical" href="https://www.gostilna-spiric.si/o-nas" />
        <meta name="description" content="Zgodovina Gostilne Spirić" />
      </Helmet>

      <article className="container mx-auto mt-8 sm:px-4">
        <section className="grid overflow-hidden rounded md:grid-rows-1 md:grid-cols-1 ">
          <div className="flex flex-col p-8 text-center bg-gray-100 sm:p-16 ">
            <h1 className="text-4xl font-light leading-snug text-yellow-600 font-sacramento">
              Naša zgodba
            </h1>
            <h1 className="my-8 text-4xl leading-snug uppercase font-playfair">
              Gostilna Spirić
            </h1>
            <div className="flex flex-col gap-5 text-justify">
              <div>
                {/* className="flex lg:justify-center" */}
                <Quote quote='"Zrno do zrna – pogača, kamen na kamen – palača."' />
              </div>
              <div className="flex flex-col gap-5 lg:gap-8 lg:flex-row lg:items-center">
                <StaticImage
                  className="h-full lg:flex-1"
                  src="../images/o-nas/spiric_notranjos.jpg"
                  alt="Notranjost gostilne Spirić"
                />
                <div className="leading-relaxed lg:flex-1">
                  <h2 className="mb-2 text-lg font-light text-yellow-600 font-playfair">
                    Gostilna Spirić
                  </h2>
                  <h3 className="mb-2 text-4xl font-medium font-playfair">
                    Začetki ...
                  </h3>
                  <p>
                    Pisalo se je leto 1974, ko je med Tomom in Danico v službi v
                    Nemčiji preskočila iskrica. Oba mlada, zagnana in željna
                    izzivov, sta še istega leta pričela z gradnjo hiše v
                    Černelavcih, v kateri sta uredila bivalne prostore in
                    gostilno. Za družino Spirić se je pričelo imenitno potovanje
                    v svet gostilniških zabav in vztrajnega ter trdega dela za
                    uresničitev svojega poslanstva….
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-5 lg:gap-8 lg:flex-row lg:items-center lg:mt-10 ">
                <StaticImage
                  className="h-full lg:order-last lg:flex-1"
                  src="../images/o-nas/spiric_branko.jpg"
                  alt="Branko Spirić"
                />
                <div className="leading-relaxed lg:flex-1">
                  <h2 className="mb-2 text-lg font-light text-yellow-600 font-playfair">
                    Gostilna Spirić
                  </h2>
                  <h3 className="mb-2 mb-6 text-4xl font-medium text-left font-playfair">
                    Gostilno prevzame sin Branko
                  </h3>
                  <p>
                    Ko je leta 2004 gostilno prevzel sin Branko, se je odločil
                    nekoliko dodatno dopolniti poslovni model gostilne. Tako se
                    je po letu dni naprej pričela organizacija vsakoletne{" "}
                    <b>Bogračijade</b>. Leto kasneje je v sodelovanju s
                    pivovarno Kozel organiziral prvi <b>Kozel fest</b>, ki je
                    vsako leto, od leta 2015 naprej potekal v začetku jeseni. Na
                    Kozel festu je bilo v 4 letih veliko izjemnih glasbenih
                    izvajalcev –{" "}
                    <b>
                      Blue Planet, Vlado Kalember, Jasmin Stavros, Magazin,
                      Učiteljica, Tarapana band in drugi glasbenih izvajalcev
                    </b>
                    .
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-5 lg:gap-8 lg:flex-row lg:items-center lg:mt-10">
                <StaticImage
                  className="h-full lg:flex-1"
                  src="../images/o-nas/spiric_gospa.jpg"
                  alt="Gostilna Spirić"
                />
                <div className="leading-relaxed lg:flex-1">
                  <h2 className="mb-2 text-lg font-light text-yellow-600 font-playfair">
                    Gostilna Spirić
                  </h2>
                  <h3 className="mb-2 mb-6 text-4xl font-medium text-left font-playfair">
                    Danes
                  </h3>
                  <p>
                    Danes se pri <span className="font-bold">Spiriću</span> iz{" "}
                    <b>Prekmurja</b>, poleg načrtovanja velikih dogodkov za v
                    prihodnje, ukvarjajo tudi s storitvami <b>cateringa</b> za
                    manjše ali večje dogodke in sodelujejo z zunanjimi partnerji
                    pri organizaciji <b>vlečenja vrvi na reki Muri</b> v
                    sodelovanju s Kajak klubom Krog ter pri organizaciji
                    vsakoletne in zelo znane <b>Bujte repe v Ljubljani</b> v
                    sodelovanju s Klubom prekmurskih študentov.
                  </p>
                </div>
              </div>
              <Quote
                className="mt-10"
                quote='"Vsi želijo živeti na vrhu gore, toda vsa sreča in rast se zgodi, ko jo plezate."'
                author="A. Rooney"
              />

              <StaticImage
                src="../images/o-nas/spiric_druzina.jpg"
                alt="Zaposleni"
              />
              <StaticImage
                src="../images/o-nas/spiric_ekipa.jpg"
                alt="Zaposleni"
              />
            </div>
          </div>
        </section>
      </article>
    </React.Fragment>
  );
}

export default ONas;
